"use client";

import { cva } from "class-variance-authority";
import { ContentBlockServer } from "../ContentBlock";
import { fixPrice } from "@/lib/utils";
import { flagFromCode, useI10n } from "@local/i10n";
import { getShippingThreshold } from "@local/cart/src/shipping";
import { IS_HAM, MINISTORE } from "@/lib/const";
import { classNames } from "@local/utils";
import { useMenuLayout } from "../HeaderMenu/useMenuLayout";
interface Props {
  text?: string;
}
const styles = cva("flex items-center justify-center h-8 align-middle font-semibold leading-none", {
  variants: {
    store: {
      cozistore: "bg-black text-white",
      baerskintactical: "absolute bg-[#54564E] text-white transition-all",
      hamstore: "bg-sky-950 text-white"
    }
  },
  defaultVariants: {
    store: "cozistore"
  }
});
export const TopPromoBanner = ({
  text
}: Props) => {
  const {
    currencyCode,
    currencySymbol,
    country
  } = useI10n();
  const {
    isOpen: menuIsOpen
  } = useMenuLayout();
  const FREE_SHIPPING_THRESHOLD = getShippingThreshold(currencyCode);
  const defaultText = `FREE %%FLAG%% %%ACTION%% ON ORDERS OVER %%CURRENCY%%%%FREE_SHIPPING_THRESHOLD%%`;
  const textToUse = text ?? defaultText;
  const flag = flagFromCode(country);
  const promoText = textToUse.replace("%%GIFT%%", "GIFT").replace("%%ACTION%%", IS_HAM ? "GIFT" : "SHIPPING").replace("%%FLAG%%", flag ?? "").replace("%%CURRENCY%%", currencySymbol)
  // .replace("%%GIFT%%", getLevel(0, currencyCode!)?.product?.productName ?? "")
  .replace("%%FREE_SHIPPING_THRESHOLD%%", fixPrice(FREE_SHIPPING_THRESHOLD).toString());
  return <div className="relative h-8 bg-zinc-900" data-sentry-component="TopPromoBanner" data-sentry-source-file="TopPromoBanner.tsx">
      <ContentBlockServer fullWidthBgColor={classNames(styles({
      store: MINISTORE
    }), menuIsOpen ? "-top-8" : "top-0")} role="banner" data-sentry-element="ContentBlockServer" data-sentry-source-file="TopPromoBanner.tsx">
        <div className="relative">
          <p className={classNames("flex-1 text-sm text-center uppercase lg:flex-none")} dangerouslySetInnerHTML={{
          __html: promoText
        }} />
        </div>
      </ContentBlockServer>
    </div>;
};