"use client";

import { useScroll } from "@local/utils";
import { useEffect, type FC } from "react";
interface ScrollHandlerProps {
  className: string;
  elementRef: React.RefObject<HTMLDivElement | null>;
  promoBannerHeight?: number;
  toggleClassName: string;
}
const ScrollHandler: FC<ScrollHandlerProps> = ({
  elementRef,
  className,
  promoBannerHeight,
  toggleClassName
}) => {
  const {
    scrollY,
    scrollDirection
  } = useScroll();
  const height = promoBannerHeight ?? 0;
  const toggle = (close: boolean) => {
    const timeout = setTimeout(() => {
      close ? elementRef.current?.classList.add(toggleClassName) : elementRef.current?.classList.remove(toggleClassName);
      clearTimeout(timeout);
    }, 200);
  };
  useEffect(() => {
    if (scrollY > height + 80) {
      elementRef.current?.classList.add(className);
      if (scrollDirection === "up") {
        elementRef.current?.classList.add(toggleClassName);
      }
    } else if (scrollY < height) {
      elementRef.current?.classList.remove(className);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);
  useEffect(() => {
    if (scrollY > height + 80) {
      toggle(scrollDirection === "up");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDirection]);
  return null;
};
export default ScrollHandler;